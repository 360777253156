html {
  font-size: 14px;
}

.body-lock {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
}

/*
 TODO Remove this fix when
 - svg buttons in table header and image viewer are replaced
 - data table has been upgraded 
 */
td svg {
  display: inline;
}

button svg {
  display: inline;
}

td {
  overflow-wrap: break-word;
}

thead td {
  /* 
  Prevent buttons inside cells from being displayed on top of headers when scrolling 
  TODO: remove when we move away from `@medmain/react-ui-kit` <List> component
  */
  z-index: 10;
}

::selection {
  color: highlighttext;
}

:focus {
  /* Override the blue color applied in Chrome browser */
  outline: #f4ecf4 auto 3px;
}

.openseadragon-canvas:focus {
  /* In recent versions of Chrome, an outline effect is applied on the image viewer when it's clicked */
  outline: none;
}

/* 
when migrating to ChakraUI 1.7.2 we lose the `vertical-align: middle` applied to SVG 
Unfortunately it's not possible to use the theme to fix this 
because there is no `Icon` component in the theme
TODO check if we need this fix with the next versions of ChakraUI
*/
.chakra-icon {
  vertical-align: middle;
}
